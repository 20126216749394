<script>
// import { badgeColor } from '../../util/mapfunction';

export default {
    name: "StatusBadge",
    created() {},
    data() {
        return {
            badge_color: "",
            badge_label: "",
        };
    },
    props: ['status', 'type'],
    // props: {
    //     status: {
    //         required: true
    //     },
    //     type : {
    //         required: true
    //     }
    // },
    methods: {
        statusLabel(status) {
            if (status === null) 
            return;

            let defaultTxt = status.replaceAll('_', ' ');
            return defaultTxt.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toLocaleUpperCase()); 
        },
        badgeColor(type, status) {
            if (status === null) 
                return;
            if (type.toLowerCase() === "tenancy") {
                switch(status.toLowerCase()) {
                    case 'draft': 
                        return "badge-dark";
                    case 'payment_processing': 
                        return "badge-primary";
                    case 'pending_payment': 
                        return "badge-secondary";
                    case 'pending_signature': 
                        return "badge-warning";
                    case 'pending_landlord_signature': 
                        return "badge-info";
                    case 'pending_tenant_signature': 
                        return "badge-light";
                    case 'pending_submit': 
                        return "badge-orange";
                    case 'pending_stamping': 
                        return "badge-pink";
                    case 'completed': 
                        return "badge-success";
                    case 'completed_without_stamping': 
                        return "badge-success";
                    case 'expired': 
                        return "badge-danger";
                }
            } else {
                switch(status.toLowerCase()) {
                    case 'paid': 
                        return "badge-dark";
                    case 'pending': 
                        return "badge-primary";
                    case 'delivered': 
                        return "badge-success";
                    case 'payment_processing': 
                        return "badge-danger";
                }
            }
        }
    },
    mounted() {
        // this.badge_color = badgeColor(this.type, this.status);
    }
};
</script>

<template>
    <div
        class="badge font-size-13" :class="badgeColor(this.type, this.status)">
        {{ statusLabel(this.status) }}
        <!-- {{ statusLabelthis.status }} -->
    </div>
</template>

<style lang="scss" scoped>
</style>